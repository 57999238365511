import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { ComiteRegionalNav } from "../../components/menus/ComiteRegionalNav"

const Aides: React.FC = () => (
  <Layout>
    <Helmet title="Aides régionales" />
    <div className="container">
      <div className="row mt-3">
        <div className="secondary-col">
          <ComiteRegionalNav />
        </div>
        <div className="main-col">
          <h1>Aides régionales</h1>

          <h2>Les aides et récompenses accordées par le CRTA du Grand Est</h2>
          <p>Tableau récapitulatif des aides accordées par le CRTA du Grand Est aux archers et aux clubs du territoire.</p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Intitulé</th>
                <th scope="col">Date</th>
                <th scope="col">Documents</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>Aides et récompenses</td>
                <td>jusqu'à saison 2022-2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/825zbRztYoNYzLs" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr> */}
              <tr>
                <td>Aides et récompenses</td>
                <td>à partir saison 2023-2024</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/We4LdZHsgjJTpBA" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Promotion Nature-3D</td>
                <td>01/09/2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/kFz3myF6H42TNrW" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>

            </tbody>
          </table>

          <hr />

          <h2>Haut niveau : les aides de la Région Grand Est</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Intitulé</th>
                <th scope="col">Date</th>
                <th scope="col">Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Soutien aux clubs de haut niveau</td>
                <td>2023</td>
                <td>
                  <a
                    href="https://www.grandest.fr/vos-aides-regionales/soutien-aux-clubs-de-haut-niveau"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-link" />Lien (grandest.fr)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Soutien aux sportifs de haut niveau</td>
                <td>2023</td>
                <td>
                  <a
                    href="https://www.grandest.fr/vos-aides-regionales/soutien-aux-sportifs-de-haut-niveau"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-link" />Lien (grandest.fr)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Soutien aux investissements sportifs</td>
                <td>2023</td>
                <td>
                  <a
                    href="https://www.grandest.fr/vos-aides-regionales/soutien-aux-investissements-sportifs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-link" />Lien (grandest.fr)
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default Aides
